<template>
    <section id="workingArea">
        <div class="gdnBorder">
            <div class="pools votes" >
                <div class="inner">
                    <div class="align-items-center">
                        <h3 style="font-size:18px; padding-top:20px;">StarSwap Governance Proposals<br><br>
                        <small>StarSwap brings a DAO governed by NFTs, community of StarCards holders can create and vote on Governance proposals.</small></h3>
                    </div>
                    <div class="text-right q-mb-sm">
                        <div class="btn" :disabled="!isMetaMaskConnected"
                            @click="showModal" style="width:200px">
                            + New Proposal
                        </div>
                    </div>
                    <div id="tableCont">
                        <q-table
                            title=""
                            :dense="$q.screen.lt.md"
                            class="transparent custom-table"
                            :data="filteredProposals"
                            :columns="columns"
                            row-key="id"
                            :rows="rows"
                            v-if="!isMobile"
                            :pagination.sync="pagination"
                        >
                        <template v-slot:body="props">
                            <q-tr :props="props" @click.native="expands(props.row.id, props)" 
                                class="cursor-pointer">
                                <q-td>
                                    <div class="star flex no-wrap">
                                        <i>
                                            <svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </i>
                                        {{getTitle(props.row.id)}}
                                    </div>
                                </q-td>
                                <q-td class="text-center">
                                    {{getStartTime(props.row.id)}}
                                </q-td>
                                <q-td class="text-center">
                                    {{getEndTime(props.row.id)}}
                                </q-td>
                                <q-td>
                                    <!-- <a :href="getCreatorURL(props.row.id)" target="_blank"> -->
                                        {{getCreator(props.row.id, 0)}}
                                    <!-- </a> -->
                                </q-td>
                                <q-td>
                                    <div :class="getStatus(props.row.id)">
                                        {{getStatus(props.row.id)}}
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props" style="margin:30px; ">
                                <q-td colspan="5" style="border-radius:20px;" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-pa-sm text-white text-bold" 
                                        style="font-size 0.5rem;">
                                        <div 
                                        style="width:50%; color:#9db2ef; font-weight:500;">
                                            <div class="q-mb-sm label">
                                                <h2>{{getTitle(props.row.id)}}</h2>
                                            </div>
                                            <div class="q-my-lg flex">
                                                <div :class="getStatus(props.row.id)">
                                                    {{getStatus(props.row.id)}}
                                                </div>
                                                <span class="q-ml-lg text-center">
                                                    <a :href="getCreatorURL(props.row.id)" target="_blank">
                                                        {{getCreator(props.row.id, 0)}}
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="brd sol">
                                                {{getDescription(props.row.id)}}
                                            </div>
                                            <div class="q-my-md brd">
                                                <div class="label">
                                                    Add your vote to the governance proposal
                                                </div>
                                                <hr style="border-top:1px solid rgba(171, 196, 255, 0.2)">
                                                <div class="text-center">
                                                    <div class="walletBtn q-my-sm" style="width:80%;color:#6fcafd;"
                                                        :disabled="!isMetaMaskConnected"  
                                                        @click="yesReply(props.row.id)">{{getYesAnswer(props.row.id, 0)}}
                                                    </div>
                                                    <div class="walletBtn" style="width:80%; color:rgb(196 168 0);"
                                                        :disabled="!isMetaMaskConnected" 
                                                        @click="noReply(props.row.id)">{{getNoAnswer(props.row.id, 0)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="q-mt-lg" style="width:40%;">
                                            <div v-if="isOwner" class="q-mb-md walletBtn"
                                                :disabled="!isMetaMaskConnected" 
                                                @click="executeProposal(props.row.id)">Execute Proposal
                                            </div>
                                            <div class="brd">
                                                <div class="label">
                                                    Information
                                                </div>
                                                <hr style="border-top:1px solid rgba(171, 196, 255, 0.2);margin-top:8px">
                                                <div class="text-left">
                                                    <div class="row">
                                                        <span class="col-5 label" style="padding:0">DAO System</span>
                                                        <span class="col-7" style="padding:0">Governed by StarCards</span>
                                                    </div>
                                                    <div class="row" style="padding:10px 0">
                                                        <span class="col-5 label" style="padding:0">Start Date</span>
                                                        <span class="col-7" style="padding:0">{{getStartTime(props.row.id)}}</span>
                                                    </div>
                                                    <div class="row">
                                                        <span class="col-5 label" style="padding:0">End Date</span>
                                                        <span class="col-7" style="padding:0">{{getEndTime(props.row.id)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="brd q-my-md">
                                                <div class="row" style="padding-bottom:10px;">
                                                     <span class="col-5 label" style="padding:0">Proposal Result:</span>
                                                        <span v-if="getResult(props.row.id) == 1" class="col-7" style="padding:0"><img src="@/assets/images/resultyes.png" alt=""
                                                                style="width:20px; height:20px;">&nbsp; &nbsp;{{getYesAnswer(props.row.id, 0)}}</span>
                                                         <span v-if="getResult(props.row.id) == 2" class="col-7" style="padding:0"><img src="@/assets/images/resultno.png" alt=""
                                                                style="width:20px; height:20px;">&nbsp; &nbsp;{{getNoAnswer(props.row.id, 0)}}</span>       
                                                   <!-- <div class="col-7" style="padding:0">
                                                        <span class="label">Result</span>
                                                    </div>
                                                    <div v-if="getResult(props.row.id) == 1" 
                                                        class="row" style="padding:0">
                                                        <img src="@/assets/images/results.png" alt=""
                                                                style="width:30px; height:30px; padding-bottom:5px">
                                                        <span style="color:#6fcafd">{{getYesAnswer(props.row.id, 0)}}</span>
                                                    </div>
                                                    <div v-if="getResult(props.row.id) == 2" 
                                                        class="row" style="padding:0">
                                                        <img src="@/assets/images/results.png" alt=""
                                                                style="width:30px; height:30px;">
                                                        <span class="col-7" classstyle="color:rgb(255, 255, 0, 1);">{{getNoAnswer(props.row.id, 0)}}</span>
                                                    </div>-->
                                                </div>
                                                <div style="font-size:12px; color:rgba(255,255,255,0.7); text-align:center">(Minimum Vote Count for "Yes" Result is 250.)</div>
                                                <hr style="border-top:1px solid rgba(171, 196, 255, 0.2);margin-top:5px">
                                                <div class="text-left">
                                                    <div>
                                                        <div class="q-mt-lg q-mb-sm">
                                                            <span 
                                                                style="color:#6fcafd; font-size:16px">
                                                                {{getYesAnswer(props.row.id, 1)}} ({{getYesTicketCount(props.row.id)}} STARCARDS)... {{calculatePercenfOfYesTicket(props.row.id)}}%
                                                            </span>
                                                        </div>
                                                        <div class="wrapper">
                                                            <div id="progress1" class="progress"></div>
                                                        </div>
                                                        <div class="q-mt-lg q-mb-sm">
                                                            <span 
                                                                style="font-size:16px; color:rgb(196 168 0);">
                                                                {{getNoAnswer(props.row.id, 1)}} ({{getNoTicketCount(props.row.id)}} STARCARDS)... {{calculatePercenfOfNoTicket(props.row.id)}}%
                                                            </span>
                                                        </div>
                                                        <div class="wrapper q-mt-md">
                                                            <div id="progress2" class="progress"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="voterInfo">
                                        <div class="flex q-mb-sm" style="font-weight:500; font-size:18px">
                                            <div class="q-pl-lg q-pr-sm">
                                                Governance Votes Submitted
                                            </div>
                                            <div class="text-center q-px-sm" 
                                                style="border-radius:10px; color:black;background:rgba(171, 196, 255, 0.9);min-width:30px">
                                                {{getVoteCount(props.row.id)}}
                                            </div>
                                        </div>
                                        <q-table
                                            id=""
                                            title=""
                                            class="transparent custom-table1"
                                            :data="filteredVoters()"
                                            :columns="columns1"
                                            row-key="id"
                                            :rows="rows1"
                                            v-if="!isMobile"
                                            table-class="my-custom"
                                            :pagination.sync="pagination1"
                                            :dense="$q.screen.lt.md"
                                        >
                                            <template v-slot:body="props">
                                                <q-tr>
                                                    <q-td class="text-bold text-center">
                                                        <a :href="getVoterURL(props.row.id)" target="_blank">
                                                            {{getVoter(props.row.id, 0)}}
                                                        </a>
                                                    </q-td>
                                                    <q-td class="text-bold text-center">
                                                        {{getVotedReply(props.row.id)}}
                                                    </q-td>
                                                    <q-td class="text-bold text-center">
                                                        {{getVotedTicket(props.row.id)}}
                                                    </q-td>
                                                </q-tr>
                                            </template>
                                        </q-table>
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                        </q-table>
                        <q-table
                            title=""
                            :dense="$q.screen.lt.md"
                            class="transparent custom-table"
                            :data="filteredProposals"
                            :columns="columnsMobile"
                            row-key="id"
                            :rows="rows"
                            v-if="isMobile"
                            :pagination.sync="pagination"
                            hide-header
                        >
                        <template v-slot:body="props">
                            <q-tr v-show="!props.expand" :props="props" @click.native="expands(props.row.id, props)" 
                                class="cursor-pointer">
                                <q-td>
                                    <div class="star flex no-wrap" style="padding-bottom:2px">
                                        <i style="margin-top:6px">
                                            <svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </i>
                                        <div>
                                            <div class="text-center text-grey">
                                                Title
                                            </div>
                                            <div>
                                                {{getTitle(props.row.id)}}
                                            </div>
                                        </div>
                                    </div>
                                </q-td>
                                <q-td>
                                    <div class="text-center text-grey" style="padding-bottom:2px">
                                        Status
                                    </div>
                                    <div :class="getStatus(props.row.id)">
                                        {{getStatus(props.row.id)}}
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props" 
                                    @click.native="expands(props.row.id, props)">
                                <q-td>
                                    <div class="star flex no-wrap" style="padding-bottom:2px">
                                        <i style="margin-top:30px">
                                            <svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </i>
                                        <div>
                                            <div class="text-center text-grey">
                                                Title
                                            </div>
                                            <div>
                                                {{getTitle(props.row.id)}}
                                            </div>
                                            <div class="text-center text-grey q-mt-sm" style="padding-bottom:2px">
                                                Start Time
                                            </div>
                                            <div class="text-center">
                                                {{getStartTime(props.row.id)}}
                                            </div>
                                        </div>
                                    </div>
                                </q-td>
                                <q-td>
                                    <div class="text-center text-grey" style="padding-bottom:2px">
                                        Status
                                    </div>
                                    <div :class="getStatus(props.row.id)">
                                        {{getStatus(props.row.id)}}
                                    </div>
                                    <div class="text-center text-grey q-mt-sm" style="padding-bottom:2px">
                                        End Time
                                    </div>
                                    <div class="text-center">
                                        {{getEndTime(props.row.id)}}
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props" style="margin:30px; ">
                                <q-td colspan="5" style="border-radius:20px;" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-py-md text-white text-bold" 
                                        style="padding-left:0; padding-right:0">
                                        <div style="width:100%; color:#9db2ef; font-weight:500;">
                                            <div class="q-mb-sm label">
                                                <h2>{{getTitle(props.row.id)}}</h2>
                                            </div>
                                            <div class="q-mt-md q-mb-sm flex text-left">
                                                <div :class="getStatus(props.row.id)" style="margin:0; width:60px">
                                                    {{getStatus(props.row.id)}}
                                                </div>
                                                <span class="q-ml-sm text-center" style="font-size:12px">
                                                    <a :href="getCreatorURL(props.row.id)" target="_blank">
                                                        {{getCreator(props.row.id, 1)}}
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="brd sol">
                                                {{getDescription(props.row.id)}}
                                            </div>
                                            <div class="q-my-md brd">
                                                <div class="label">
                                                    Add your vote to the governance proposal
                                                </div>
                                                <hr style="border-top:1px solid rgba(171, 196, 255, 0.2)">
                                                <div class="text-center">
                                                    <div class="walletBtn q-my-sm" style="width:80%;color:#6fcafd"
                                                        :disabled="!isMetaMaskConnected"  
                                                        @click="yesReply(props.row.id)">{{getYesAnswer(props.row.id, 0)}}</div>
                                                    <div class="walletBtn" style="width:80%;color:rgb(196 168 0);"
                                                        :disabled="!isMetaMaskConnected" 
                                                        @click="noReply(props.row.id)">{{getNoAnswer(props.row.id, 0)}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="q-mt-lg" style="width:100%;">
                                            <div class="brd">
                                                <div v-if="isOwner" class="q-mb-md walletBtn"
                                                    :disabled="!isMetaMaskConnected" 
                                                    @click="executeProposal(props.row.id)">Execute Proposal
                                                </div>
                                                <div class="label">
                                                    Information
                                                </div>
                                                <hr style="border-top:1px solid rgba(171, 196, 255, 0.2)">
                                                <div class="text-left">
                                                    <div class="row">
                                                        <span class="col-5 label" style="padding:0">Voting system</span>
                                                        <span class="col-7" style="padding:0">Single choice voting</span>
                                                    </div>
                                                    <div class="row" style="padding:10px 0">
                                                        <span class="col-5 label" style="padding:0">Start Date</span>
                                                        <span class="col-7" style="padding:0">{{getStartTime(props.row.id)}}</span>
                                                    </div>
                                                    <div class="row">
                                                        <span class="col-5 label" style="padding:0">End Date</span>
                                                        <span class="col-7" style="padding:0">{{getEndTime(props.row.id)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="brd q-my-md">
                                                <div class="row">
                                                    <div class="col-7" style="padding:0">
                                                        <span class="label">Proposal Result:</span>
                                                       <!--  <span v-if="getResult(props.row.id) == 1" class="col-4 text-right" style="padding:0"><img src="@/assets/images/resultyes.png" alt=""
                                                                style="width:20px; height:20px;">&nbsp; &nbsp;{{getYesAnswer(props.row.id, 0)}}</span>
                                                         <span v-if="getResult(props.row.id) == 2" class="col-4 text-right" style="padding:0"><img src="@/assets/images/resultno.png" alt=""
                                                                style="width:20px; height:20px;">&nbsp; &nbsp;{{getNoAnswer(props.row.id, 0)}}</span> -->
                                                    </div>
                                                    <div v-if="getResult(props.row.id) == 1" 
                                                        class="col-4 text-right" style="padding:0">
                                                        <img src="@/assets/images/resultyes.png" alt=""
                                                                style="width:20px; height:20px;">
                                                        <span style="color:#6fcafd;">&nbsp;&nbsp;{{getYesAnswer(props.row.id, 0)}}</span>
                                                    </div>
                                                    <div v-if="getResult(props.row.id) == 2" 
                                                        class="col-4 text-right" style="padding:0">
                                                        <img src="@/assets/images/resultno.png" alt=""
                                                                style="width:20px; height:20px;">
                                                        <span style="color:rgb(196 168 0)">&nbsp;&nbsp;{{getNoAnswer(props.row.id, 0)}}</span>
                                                    </div>
                                                </div>
                                                <div style="font-size:12px; color:rgba(255,255,255,0.7); text-align:center">(Minimum Vote Count for "Yes" Result is 250.)</div>
                                                <hr style="border-top:1px solid rgba(171, 196, 255, 0.2)">
                                                <div class="text-left">
                                                    <div>
                                                        <div class="q-mt-lg q-mb-sm">
                                                            <span class="result" style="color:#6fcafd;">
                                                                {{getYesAnswer(props.row.id, 1)}} ({{getYesTicketCount(props.row.id)}} STARCARDS)... {{calculatePercenfOfYesTicket(props.row.id)}}%
                                                            </span>
                                                        </div>
                                                        <div class="wrapper">
                                                            <div id="progress1" class="progress"></div>
                                                        </div>
                                                        <div class="q-mt-lg q-mb-sm">
                                                            <span class="result" style="color:rgb(196 168 0);">
                                                                {{getNoAnswer(props.row.id, 1)}} ({{getNoTicketCount(props.row.id)}} STARCARDS)... {{calculatePercenfOfNoTicket(props.row.id)}}%
                                                            </span>
                                                        </div>
                                                        <div class="wrapper q-mt-md">
                                                            <div id="progress2" class="progress"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="voterInfo">
                                        <div class="flex q-mb-sm voterCount">
                                            <div class="q-pl-md q-pr-sm">
                                                Governance Votes Submitted
                                            </div>
                                            <div class="text-center q-px-sm" 
                                                style="border-radius:10px; color:black;background:rgba(171, 196, 255, 0.9);min-width:30px">
                                                {{getVoteCount(props.row.id)}}
                                            </div>
                                        </div>
                                        <q-table
                                            id=""
                                            title=""
                                            class="transparent custom-table1"
                                            :data="filteredVoters()"
                                            :columns="columnsMobile1"
                                            row-key="id"
                                            :rows="rows1"
                                            v-if="isMobile"
                                            table-class="my-custom"
                                            :pagination.sync="pagination1"
                                            :dense="$q.screen.lt.md"
                                        >
                                            <template v-slot:body="props">
                                                <q-tr>
                                                    <q-td class="text-bold text-center">
                                                        <a :href="getVoterURL(props.row.id)" target="_blank">
                                                            {{getVoter(props.row.id, 1)}}
                                                        </a>
                                                    </q-td>
                                                    <q-td class="text-bold text-center">
                                                        {{getVotedReply(props.row.id)}}
                                                    </q-td>
                                                    <q-td class="text-bold text-center">
                                                        {{getVotedTicket(props.row.id)}}
                                                    </q-td>
                                                </q-tr>
                                            </template>
                                        </q-table>
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                        </q-table>
                    </div>
                </div>
                <div v-if="showModalProposal" class="overlay">
                    <div class="gdnBorder voteModal">
                        <div class="modal__content_view_proposal" v-click-outside="onClickOutside">
                            <div class="model__content__item">
                                <div><h3>New Proposal</h3></div>
                                    <q-btn icon="close" flat round dense
                                        @click="showModalProposal = !showModalProposal" color="white">
                                </q-btn>
                            </div>
                            <div class="q-mb-md">
                                <div style="color:rgba(255,255,255,0.5); font-weight:500">
                                    <!-- <img :src="require('@/assets/images/warning2.jpg')"> -->
                                    You need to have a minimum of 10 StarCards in order to submit a proposal.
                                </div>
                            </div>
                            <div class="flex q-mb-sm">
                                 <div class="flex" style="align-items:center">
                                    <span class="label q-mr-sm">From</span>
                                    <datepicker 
                                        v-model="startTime" format="yyyy-MM-dd">
                                    </datepicker>
                                    <span class="label q-mx-sm">~</span>
                                    <span class="label q-mr-sm">To</span>
                                    <datepicker class="endTime"
                                        v-model="endTime" format="yyyy-MM-dd">
                                    </datepicker>
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <div class="label">Title</div>
                                <div>
                                    <q-input standout="bg-indigo-5"  
                                        type="text" placeholder="Title of proposal"
                                        v-model="title"
                                        :bottom-slots="false" :hide-bottom-space="true">
                                    </q-input>
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <div class="label">Description</div>
                                <div>
                                    <textarea class="form-control" spellcheck="true" v-model="description"
                                        placeholder=""/>
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <div class="label">Yes</div>
                                <div>
                                    <q-input standout="bg-indigo-5"  
                                        type="text" placeholder="Yes, I want ..."
                                        v-model="yesAnswer"
                                        :bottom-slots="false" :hide-bottom-space="true">
                                    </q-input>
                                </div>
                            </div>
                            <div class="q-my-sm">
                                <div class="label">No</div>
                                <div>
                                    <q-input standout="bg-indigo-5"  
                                        type="text" placeholder="No, I do not ..."
                                        v-model="noAnswer"
                                        :bottom-slots="false" :hide-bottom-space="true">
                                    </q-input>
                                </div>
                            </div>
                            <div class="model__content__item">
                                <div id="claim"
                                    @click="CreateProposal" class="walletBtn q-mt-sm">Create
                                </div>
                                <div id="claim"
                                    @click="showModalProposal = !showModalProposal" 
                                    class="q-ml-lg walletBtn q-mt-sm">Close
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </section>
</template>
<script>
  import BigNumber from 'bignumber.js';
  import vClickOutside from 'v-click-outside'
import Datepicker from 'vuejs-datepicker';

  export default {
    name: "farm",
    components: {
        Datepicker
    },
    data() {
        return {
            rerender: 0,
            filter: 'all',
            pagination: {
                rowsPerPage: 30
            },
            pagination1: {
                rowsPerPage: 10
            },
            start_at: '',
            end_at: '',
            columns: [
                {
                    name: 'title',
                    field: 'title',
                    label: 'Title',
                    align: 'center',
                },
                {
                    name: 'startTime',
                    field: 'startTime',
                    label: 'Start Time',
                    align: 'center',
                },
                {
                    name: 'endTime',
                    field: 'endTime',
                    label: 'End Time',
                    align: 'center',

                },
                {
                    name: 'creator',
                    field: 'creator',
                    label: 'Creator',
                    align: 'center',
                },
                {
                    name: 'status',
                    field: 'status',
                    label: 'Status',
                    align: 'center',
                },
            ],
            columns1: [
                {
                    name: 'voter',
                    field: 'voter',
                    label: 'Voted by',
                    align: 'center',
                },
                {
                    name: 'content',
                    field: 'content',
                    label: 'Decision',
                    align: 'center',
                },
                {
                    name: 'ticketCount',
                    field: 'ticketCount',
                    label: 'Votes Count',
                    align: 'center',

                },
            ],
            columnsMobile: [
                {
                    name: 'title',
                    field: 'title',
                    label: 'Title',
                    align: 'center',
                },
                {
                    name: 'status',
                    field: 'status',
                    label: 'Status',
                    align: 'center',
                },
            ],
            columnsMobile1: [
                {
                    name: 'voter',
                    field: 'voter',
                    label: 'Voter',
                    align: 'center',
                },
                {
                    name: 'content',
                    field: 'content',
                    label: 'Decision',
                    align: 'center',
                },
                {
                    name: 'ticketCount',
                    field: 'ticketCount',
                    label: 'Votes',
                    align: 'center',

                },
            ],
            rows1: [
            ],
            showModalProposal: false,
            startTime: null,
            endTime: null,
            title: '',
            description: '',
            yesAnswer: '',
            noAnswer: '',
            currentIndex: null,
            oldExpandRow: null,
            oldExpandRowProps: null
        };
    },
    mounted() {
    },
    computed: {
        isMobile() {
            return this.$q.screen.width < 996;
        },
        isMetaMaskConnected() {
            return this.$store.state.account!=null;
        },
        isOwner() {
            if (this.$store.state.starCardsGovernance.owner == null || 
                this.$store.state.account == null)
                return false;

            if (this.$store.state.starCardsGovernance.owner.toLowerCase() 
                == this.$store.state.account.address.toLowerCase()) {
                return true;
            }
            else
                return false;
        },
        rows() {
            return this.$store.state.starCardsGovernance.proposalInfo;
        },
        filteredProposals() {
            var rows = [];
            let length = this.$store.state.starCardsGovernance.proposalCount;
            for (let i = 0; i < length; i++) {
                rows[i] = {
                    id : i
                }
            }
           
            return rows
        },
    },
    methods: {
        forceUpdate() {
            this.rerender++;
        },
        onClickOutside (event) {
            this.showModalProposal = false;
        },
        filteredVoters() {
            var rows = [];
            let length = this.$store.state.starCardsGovernance.currentVoters.length;
            for (let i = 0; i < length; i++) {
                rows[i] = {
                    id : i,
                }
            }
            return rows
        },
        expands(index, props) {
            if (!this.isMetaMaskConnected)
                return;

            if (this.oldExpandRow != null && index == this.oldExpandRow) {
                props.expand = !props.expand;
                this.oldExpandRow = null;
                this.oldExpandRowProps = null;
                return;
            }

            if (this.oldExpandRow != null && index != this.oldExpandRow) {
                this.oldExpandRowProps.expand = !this.oldExpandRowProps.expand;
            }

            this.$store.dispatch('getProposalInformation', {
                index: index
            }).then(()=>{
                props.expand = !props.expand;

                if (props.expand) {
                    this.oldExpandRow = index;
                    this.oldExpandRowProps = props;
                    this.currentIndex = index;
                }
            });
        },
        getTitle(index) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';

            return this.showTitle(this.$store.state.starCardsGovernance.proposalInfo[index][3]);
        },
        getStartTime(index) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';

            return this.getDate(this.$store.state.starCardsGovernance.proposalInfo[index][0]);
        },
        getEndTime(index) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';

            return this.getDate(this.$store.state.starCardsGovernance.proposalInfo[index][1]);
        },
        getCreator(index, mobile) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';

            if (mobile == 0)
                return this.showWallet(this.$store.state.starCardsGovernance.proposalInfo[index][2]);
            else
                return this.showWalletInMobile(this.$store.state.starCardsGovernance.proposalInfo[index][2], 0);
        },
        getCreatorURL(index) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';
                
            let url = "https://blockscout.com/astar/address/" + 
                this.$store.state.starCardsGovernance.proposalInfo[index][2];
            return url;
        },
        getStatus(index) {
            if (this.$store.state.starCardsGovernance.status.length == 0)
                return '';

            if (this.$store.state.starCardsGovernance.status[index] == 0)
                return 'Pending'
            else if (this.$store.state.starCardsGovernance.status[index] == 1)
                return 'Active';
            else
                return 'Closed';
        },
        getDescription(index) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';

            return this.$store.state.starCardsGovernance.proposalInfo[index][6];
        },
        getYesAnswer(index, kind) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';

            return this.showAnswer(this.$store.state.starCardsGovernance.proposalInfo[index][4], kind);
        },
        getNoAnswer(index, kind) {
            if (this.$store.state.starCardsGovernance.proposalInfo.length == 0)
                return '';

            return this.showAnswer(this.$store.state.starCardsGovernance.proposalInfo[index][5], kind);
        },
        showTitle(title) {
            if (this.$q.screen.width >= 996) {
                if (title.length < 28)
                    return title;

                return (
                    title.substring(0, 28) +
                    "..."
                );
            }
            else
                if (title.length < 22)
                    return title;

                return (
                    title.substring(0, 22) +
                    "..."
                );
        },
        showAnswer(title, kind) {
            let length;
            if (kind == 0)
                length = 25;
            else
                length = 15;

            if (title.length < length)
                return title;

            return (
                title.substring(0, length) +
                "..."
            );
        },
        showWallet(wallet) {
            return (
                wallet.substring(0, 8) +
                "..." +
                wallet.substring(wallet.length - 4, wallet.length)
            );
        },
        showWalletInMobile(wallet, kind) {
            if (kind == 0)
                return (
                    wallet.substring(0, 10) +
                    "..." +
                    wallet.substring(wallet.length - 5, wallet.length)
                );
            else
                return (
                    wallet.substring(0, 6) +
                    "..." +
                    wallet.substring(wallet.length - 4, wallet.length)
                );
        },
        getDate(amount) {
            let date = amount * 1000;
            // const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
            const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
            const dtf = new Intl.DateTimeFormat('en-US', options);
            return dtf.format(date);
        },
        showModal(index) {
            this.startTime = '';
            this.endTime = '';
            this.title = '';
            this.yesAnswer = '';
            this.noAnswer = '';
            this.description = '';

            this.showModalProposal = true;
        },
        async executeProposal(index) {
            await this.$store.dispatch('executeProposal', {
                index: index
            }).then(()=>{
                this.$store.dispatch('getProposalInformation', {
                    index: index
                });

                this.forceUpdate();
            });
        },
        CreateProposal() {
            var startTime = new Date(this.startTime).getTime() / 1000 + 5 * 60;      // + 5 min
            var endTime = new Date(this.endTime).getTime() / 1000 + 5 * 60;

            this.$store.dispatch('createProposal', {
                startTime:startTime,
                endTime: endTime,
                title: this.title,
                description: this.description,
                yesAnswer: this.yesAnswer,
                noAnswer: this.noAnswer
            });

            this.forceUpdate();
        },
        yesReply(index) {
            this.$store.dispatch('voteOnProposal', {
                index: index,
                reply:0
            }).then(()=>{
                this.$store.dispatch('getProposalInformation', {
                    index: index
                });

                this.forceUpdate();
            });
        },
        async noReply(index) {
            await this.$store.dispatch('voteOnProposal', {
                index: index,
                reply:1
            }).then(()=>{
                this.$store.dispatch('getProposalInformation', {
                    index: index
                });

                this.forceUpdate();
            });
        },
        getYesTicketCount(index) {
            if (this.currentIndex != null && index != this.currentIndex) {
                return '0';
            }
            if (this.$store.state.starCardsGovernance.currentProposal == null)
                return '0';

            return this.$store.state.starCardsGovernance.currentProposal[7]
        },
        getNoTicketCount(index) {
            if (this.currentIndex != null && index != this.currentIndex) {
                return '0';
            }

            if (this.$store.state.starCardsGovernance.currentProposal == null)
                return '0';
            return this.$store.state.starCardsGovernance.currentProposal[8]
        },
        calculatePercenfOfYesTicket(index) {
            if (document.getElementsByClassName('progress')[2*index] == null)
                return;

            if (this.currentIndex != null && index != this.currentIndex) {
                document.getElementsByClassName('progress')[2*index].style.width = 0 + "%";
                return '0';
            }

            if (this.$store.state.starCardsGovernance.currentProposal == null)
                return '0';
            let totalTicket = Number(this.$store.state.starCardsGovernance.currentProposal[7])
                            + Number(this.$store.state.starCardsGovernance.currentProposal[8]);

            if (totalTicket == 0)
                return '0';

            let percent = Number(this.$store.state.starCardsGovernance.currentProposal[7]) * 100 
                            / totalTicket;

            document.getElementsByClassName('progress')[2*index].style.width = percent.toFixed(0) + "%";

            return percent.toFixed(0);
        },
        calculatePercenfOfNoTicket(index) {
            if (document.getElementsByClassName('progress')[2*index] == null)
                return;
                
            if (this.currentIndex != null && index != this.currentIndex) {
                document.getElementsByClassName('progress')[2*index+1].style.width = 0 + "%";
                return '0';
            }

            if (this.$store.state.starCardsGovernance.currentProposal == null)
                return '0';

            let totalTicket = Number(this.$store.state.starCardsGovernance.currentProposal[7])
                            + Number(this.$store.state.starCardsGovernance.currentProposal[8]);

            if (totalTicket == 0)
                return '0';

            let yesPercent = Number(this.$store.state.starCardsGovernance.currentProposal[7]) * 100 
                            / totalTicket;
            let noPercent = 100 - Number(yesPercent.toFixed(0));

            document.getElementsByClassName('progress')[2*index+1].style.width = noPercent + "%";

            return noPercent;
        },
        getVoter(index, kind) {
            if (kind == 0)
                return this.showWallet(this.$store.state.starCardsGovernance.currentVoters[index][0]);
            else
                return this.showWalletInMobile(this.$store.state.starCardsGovernance.currentVoters[index][0], 1);
        },
        getVoterURL(index) {
            return "https://blockscout.com/astar/address/" + 
                    this.$store.state.starCardsGovernance.currentVoters[index][0];
        },
        getVotedTicket(index) {
            return this.$store.state.starCardsGovernance.currentVoters[index][1];
        },
        getVotedReply(index) {
            if (this.currentIndex == null)
                return '';

            if (this.$store.state.starCardsGovernance.currentVoters[index][2] == 0)
                return this.showAnswer(this.$store.state.starCardsGovernance.proposalInfo[this.currentIndex][4], 0);
            else
                return this.showAnswer(this.$store.state.starCardsGovernance.proposalInfo[this.currentIndex][5], 0);
        },
        getVoteCount(index) {
            return this.$store.state.starCardsGovernance.currentVoters.length;
        },
        getResult(index) {
            if (this.$store.state.starCardsGovernance.currentProposal == null)
                return '';

            let result = this.$store.state.starCardsGovernance.currentProposal[10];
            return result;
        }
    }
  }
</script>

<style>
    .text-white {
         color:#abc4ed !important;
    }
    .votes a {
        color: #9db2ef !important;
    }

    .votes a:hover {
        color: rgba(255,255,255,0.8) !important;
        text-decoration: none !important;
    }

    tr.spaceUnder>td {
        padding-bottom: 10em !important;
    }

    .votes .overlay {
        position: fixed;
        top: 70px;
        bottom: 0;
        left: 220px;
        padding-right:5px;
        right: 0;
        background-color: #0000006c;
        backdrop-filter: blur(2px) !important;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal__content_view_proposal {
        width: 610px;
        padding: 10px 30px;
        /* border: 2px solid rgba(63, 210, 255, 0.308); */
        border-radius: 20px;
        background-color:rgba(23,45,102,1);
        font-weight: bold;
        color:#abc4ed;
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }

    .modal__content_view_proposal .row, .modal__content_view_proposal .col{
        margin-right: 0px !important;
        margin-left: 0px !important;
        padding:0 !important;
    }

    .form-control {
        border: none !important;
        color: #9db2ef  !important;
        height:90px !important; 
        background-color:rgba(69 159 233 / 25%) !important;
    }

    .votes .q-field__control {
        height: 32px;
    }

    .votes input {
        padding:0 10px !important;
    }

    .votes .overlay h3 {
        font-size: 1.3rem !important;
    }

    .votes h2 {
        font-size: 1.5rem !important;
    }

    .vdp-datepicker__calendar {
        position: absolute;
        z-index: 100;
        background: #9db2ef !important;
        color:black;
        font-size: 0.8rem;
        display: show !important;
    }

    .vdp-datepicker input {
        border-radius: 5px !important;
        padding: 0 10px;
        font-size : 0.9rem ;
        width:110px;
        background: #9db2ef;
    }

    .endTime .vdp-datepicker__calendar {
        left:-180px !important
    }

    .votes table thead  tr th:last-child {
        padding-right:30px;
    }

    .votes table tbody tr td {
        /* white-space:normal !important */
    }

    .votes .brd {
        justify-content: space-between;
        align-items: center;
        border: rgba(106, 129, 188, 0.2) solid 1px;
        border-radius: 15px;
        padding: 20px;
        white-space:normal !important;
        font-weight: 500;
        color:#abc4ed;
    }

    .votes .label {
        font-weight: 500 !important;
    }

    .Active {
        border-radius: 20px;
        background:rgba(0,128,0,1);
        font-size: 14px;
        padding:2px 10px;
        color:rgba(255,255,255,0.9);
        text-align: center;
    }

    .Pending {
        border-radius: 20px;
        background: rgba(227,227,127,1);
        font-size: 14px;
        padding:2px 10px;
        color:rgba(0,0,0,0.9);
        text-align: center;
    }

    .Closed {
        border-radius: 20px;
        background:rgb(54, 95, 73);
        font-size: 14px;
        padding:2px 10px;
        color:rgba(255,255,255,0.9);
        text-align: center;
    }

    .progress {
        width: 0%;
        height: 10px !important;
        border-radius: 50px 5px 5px 50px;
        position: relative;
        box-shadow: 
            /* This is the top gradient for the color bar */
            inset 0 10px 0 rgba(255,255,255,0.2),
            inset 0 2px 2px rgba(0,0,0,0.125);
        cursor: pointer;
        margin: 0 0px;
        border-right: 1px solid rgba(0,0,0,0.75);
    }

    #progress1 {
        background-color: #00a3ff!important;
    }

    #progress2 {
        background-color: rgb(196 168 0)!important;
    }


    .wrapper {
        width: 100%;
        background:#abc4ed;
        padding: 0;
        border-radius: 30px;
        margin: 0 auto;
        border: 1px solid rgba(0,0,0,.75);
        box-shadow: 0 1px 2px hsl(0deg 0% 100% / 45%);
        position: relative;
        height:12px;
    }

    .votes .custom-table1 {
        width:95% !important;
    }

    .voterInfo {
        border: rgba(106, 129, 188, 0.2) solid 1px;
        background: #42a1e821;
        background: linear-gradient(135deg, #42a1e821 0%,#151849 100%);
        font-size:12px;
        border-radius: 15px;
        width:80%;
        margin:0 auto;
        padding:20px 0px 10px 0px;
    }

    .votes .custom-table1 thead tr th {
        background-color: #151849 !important;
    }

    .votes table {
        color: #abc4ed;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        /* margin-top: 20px; */
        border-collapse: separate;
        border-spacing: 0 0.4rem !important;
    }

    .votes .custom-table1 .q-table thead tr, 
    .votes .custom-table1 .q-table tbody td {
        height: 35px;
        font-size:14px !important
    }

    .votes .custom-table1 table thead th {
        padding: 10px;
        font-weight: 500;
    }

    .voterInfo .q-table__bottom .q-table__control {
        height:40px !important;
    }

    .voterInfo .q-table__bottom .q-field__marginal {
        height: 40px !important;
    }

    .voterInfo .q-table__bottom .q-table__control .q-table__bottom-item {
        margin:0 !important;
    }

    .result {
        color:rgba(255,255,255,0.8); 
        font-size:16px;
    }

    .voterCount {
        font-weight:500;
        font-size:18px
    }

@media(max-width: 996px) {
    
    .modal__content_view_proposal {
        width: 100% !important;
        padding: 20px 20px !important;
    }

    .voteModal {
        margin:0 10px !important;
    }

    .votes div.star svg {
        width: 12px;
        height: 12px;
        stroke: rgba(171,196,255,.5);
        fill: none;
        transition: 0.3s linear all;
    }

    .Active, .Pending, .Closed {
        font-size: 10px !important;
        padding:2px 0px !important;
        width:100%;
        margin:0 auto;
    }

    .votes table thead tr th {
        font-size: 12px !important;
    }

    .votes table thead  tr th:last-child {
        padding-right:10px !important;
    }

    .votes table tbody  tr td:last-child {
        padding-right:10px !important;
    }

    .votes table td:first-child {
        padding-left: 10px !important
    }

    .votes .label {
        font-size:0.9rem !important;
    }

    .votes div.walletBtn {
        font-size: 12px !important;
    }

    .votes .brd {
        font-size:14px !important;
    }

    .result {
        font-size:12px !important;
    }

    .voterInfo {
        width: 100% !important;
    }

    .voterCount {
        font-weight:500;
        font-size:14px
    }
}
</style>
